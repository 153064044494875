<template>
<v-app>
  <v-layout class="rounded-md">
    <v-app-bar
        color="green"
        height="48"
        scroll-behavior="elevate"
        density="compact"
        xxtitle="$t('app.title')"
      >
      <v-toolbar-title>{{ $t('app.title') }}</v-toolbar-title>
      <v-btn :icon="gpsIcon" @click="gps_resume">
      </v-btn>

      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    </v-app-bar>
    <v-navigation-drawer class="flex-column pa-1" v-model="drawer" color="grey-darken-1" temporary >

        <nav class="d-flex flex-column pa-1" v-show="(currentPath !== '/') && (currentPath !== '/lang')" style="height:100%;">
        <v-list class :lines="false" density="compact" >
          <v-list-item class="text-center">

            <v-dialog max-width="75vw" dir="ltr">
              <template v-slot:activator="{ props: activatorProps }">
                <v-icon v-bind="activatorProps" icon="$mdiAccount"></v-icon><br/>
              </template>

              <template v-slot:default="{ isActive }" >
                <v-card title="App Info" dir="ltr" class="text-left">
                  <v-card-text>
                      App Version: {{version}}<br/>
                      user: {{ uuidController.getUUID() }}<br/>
                      userAgent: {{ userAgent }}<br/>
                      <hr/>
                      <PWAPermissions class="rounded-lg" style="direction:ltr;"></PWAPermissions>
                      <template v-if="gpsService.permission=='granted'">
                        {{ gpsService.latitude }} {{ gpsService.longitude }}<br/>
                      </template>
                      <template v-if="gpsService.error">
                        {{ gpsService.error?.message }} ({{gpsService.error?.code}})<br/>
                      </template>

                  </v-card-text>

                  <v-card-actions class="justify-space-around">
                    <v-spacer></v-spacer>
                    <v-btn
                      text="Close Dialog"
                      @click="isActive.value = false"
                    ></v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item color="primary" to="/lang" density="compact">
            <template v-slot:prepend>
            </template>
            <v-list-item-title class="text-center">
              <v-icon size="large" color="green" :icon="mdiTranslate"></v-icon>
            </v-list-item-title>
          </v-list-item>

          <v-list-item color="primary" to="/sh2" density="compact">
            <template v-slot:prepend>
              <v-icon size="large" color="green" :icon="mdiPencilBoxMultiple"></v-icon>
            </template>
            <v-list-item-title>{{$t('app.menu.continue_reporting')}}</v-list-item-title>
          </v-list-item>

          <v-list-item color="primary" to="/ongoing-feedback" density="compact">
            <template v-slot:prepend>
              <v-icon size="large" color="green" :icon="mdiChartBar"></v-icon>
            </template>
            <v-list-item-title>{{$t('app.menu.ongoing_feedback')}}</v-list-item-title>
          </v-list-item>

          <v-list-item color="primary" to="/final-feedback" density="compact">
            <template v-slot:prepend>
              <v-icon size="large" color="green" :icon="mdiChartBar"></v-icon>
            </template>
            <v-list-item-title>{{$t('app.menu.final_feedback')}}</v-list-item-title>
          </v-list-item>

          <v-list-item color="primary" to="/contact" density="compact">
            <template v-slot:prepend>
              <v-icon size="large" color="green" :icon="mdiEmailOutline"></v-icon>
            </template>
            <v-list-item-title>{{$t('app.menu.contact')}}</v-list-item-title>
          </v-list-item>

        </v-list>

        <div class="flex-grow-1">&nbsp;</div><!-- Spacer to push bottom content to the bottom -->

        <v-list class="d-col" :lines="false" density="compact">
          <v-divider></v-divider>
          <!--v-list-item class="text-center">
            <v-btn icon  @click="display_version" variant="tonal" color="blue"><v-avatar color="blue"><span class="text-h5">i</span></v-avatar></v-btn>
          </v-list-item-->
          <!--v-list-item class="text-center">
            <v-btn @click="toggleBackground()" variant="flat" color="green">Background</v-btn>
          </v-list-item-->
          <v-list-item class="text-center" v-if="isUpdateAvailable">
            <v-btn icon  v-if="isUpdateAvailable" @click="updateServiceWorkerNow" variant="tonal" color="red">Update Now</v-btn>
          </v-list-item>
          <v-list-item class="text-center" dir="ltr"><v-btn ref="refVersionButton" @click="handleDebugClick" :ripple="false" variant="plain" color="white">{{version}} <span v-if="isInstalled">S</span></v-btn></v-list-item>

          <!--v-list-item class="text-center"><v-btn @click="debugActions.addRandomReport" variant="flat" color="blue">Add Random Report</v-btn></v-list-item>
          <v-list-item class="text-center"><v-btn @click="debugActions.deleteLastReport" variant="flat" color="blue">Del Last Report</v-btn></v-list-item>
          <v-divider class="mb-6"></v-divider>
          <v-list-item class="text-center"><v-btn @click="debugActions.resetAll" variant="flat" color="red">Reset All Data</v-btn></v-list-item-->
          <v-divider></v-divider>

          <!--v-list-item><router-link class="routerlink" to="/notification">notification</router-link></v-list-item-->
        </v-list>
      </nav>
    </v-navigation-drawer>
    <v-app-bar v-if="!drawer"
        height="auto !important"
        scroll-behavior="elevate"
        density="compact"
      >
      <v-toolbar-title class="ma-0 rounded-0" ><RecommendInstallation></RecommendInstallation>
      </v-toolbar-title>
    </v-app-bar>

    <v-main class="d-flex flex-column align-center justify-center fill-height" :class="backgroundClass" style="min-height: 300px;">
       <router-view :key="routerViewKey"/>
    </v-main>
  </v-layout>

  <v-dialog max-width="75vw" v-model="debugDialog" persistent dir="ltr">
    <template v-slot:default="{ isActive }" >
      <v-card title="App Info" dir="ltr" class="text-left">
        <v-card-text>
          <div class="d-flex flex-column ga-3">
            <div>App Version: {{version}}</div>
            <div>user: {{ uuidController.getUUID() }}</div>
            <DebugTools></DebugTools>

            <div v-if="lastError">SW Last Error: {{ lastError }}</div>
            <div>SW State: {{ state }}</div>

            <LogDialog :logs="logs" @clearLog="clearLog();" >
              <template v-slot:buttonText>
                  Show Log
              </template>
            </LogDialog>
            <LogDialog :logs="gLogs" @clearLog="gClearLog();" >
              <template v-slot:buttonText>
                Show Global Log
              </template>
            </LogDialog>

            <div><router-link class="routerlink" to="/info">General Info</router-link></div>
            <div><router-link class="routerlink" to="/consent-form">Consent</router-link></div>
            <div><v-btn @click="debugActions.regDayBack" variant="flat" color="blue">reg day back</v-btn></div>
            <div><v-btn @click="debugActions.regDayFwd" variant="flat" color="blue">reg day fwd</v-btn></div>

            <div><v-btn @click="testUseWebNotification();" variant="flat" color="blue">test UseWebNotification</v-btn></div>
            <div><v-btn @click="onTryForcedSync();" variant="flat" color="blue">Try forced Sync</v-btn></div>
            <div><v-btn @click="debugActions.addRandomReport" variant="flat" color="blue">Add Random Report</v-btn></div>
            <div><v-btn @click="debugActions.deleteLastReport" variant="flat" color="blue">Del Last Report</v-btn></div>
            <div><v-btn @click="debugActions.sendNotificationNow()" variant="flat" color="blue">Notification now</v-btn></div>
            <div><v-btn @click="debugActions.scheduleNotification30Sec()" variant="flat" color="blue">notification in 2 min</v-btn></div>
            <div><v-btn @click="onReloadRouterView" variant="flat" color="blue">Reload Router View</v-btn></div>
            <div><v-btn @click="set_alerts(true);" variant="flat" color="blue">Turn Alerts on</v-btn></div>
            <div><v-btn @click="set_alerts(false);" variant="flat" color="blue">Turn Alerts off</v-btn></div>
            <div><v-btn @click="c_alert('test');" variant="flat" color="blue">Test Alert</v-btn></div>


            <div>routerViewKey={{ routerViewKey }}</div>

            <div>{{gpsService.permission}} {{gpsService.state}} ({{gpsService.latitude}},{{gpsService.longitude}}) {{ gpsService.error?.message }} ({{gpsService.error?.code}}) </div>
            <div><v-btn @click="gps_resume();" variant="flat" color="blue">resume gps</v-btn></div>
            <div><v-btn @click="gps_pause();" variant="flat" color="blue">pause gps</v-btn></div>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <div><v-btn @click="debugActions.resetAll" variant="flat" color="red">Reset All Data</v-btn></div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-spacer></v-spacer>

          <v-btn text="Close" @click="isActive.value = false"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>


</v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
/*
xmain {
  / *background-image: url("/public/img/d480b1d4-e18a-4620-94db-0734afd5a831.webp"); * /
  background-image: url("/public/img/calm_to_distopian_background.webp");
  background-attachment: fixed; /* Keep the background image fixed during scrolling * /
  background-position: center center; /* Center the image * /
  background-size: cover; /* Ensure the image covers the whole area, might crop it * /
}
*/

main.gradientBackground {
  background-color:#0070c0;
  background: linear-gradient(-60deg, #104862 0%, #0070c0 35%, #359ccf 50%, #0070c0 65%, #104862 100%);
}

/*
main.kBackgroundYellow {
  background-image: url('@/assets/Ybg.png');
  background-attachment: fixed; /* Keep the background image fixed during scrolling * /
  background-position: center center; /*3vw 10vh, 10vw 50vh, 6vw 15vh ;* /
  background-size: cover;
  /*
  background-color:#1fc90e;
  clip-path: polygon(60% 50%, 70% 30%, 70% 70%, 60% 50%),
             polygon(30% 30%, 70% 30%, 70% 70%, 30% 30%),
             polygon(30% 30%, 70% 30%, 70% 70%, 30% 30%)
  ;*/
  /*min-height: 100vh;* /

}
*/
main.kBackgroundBlue {
  background-image: url('@/assets/BBg.png');/*, url('@/assets/Ybird.png'), url('@/assets/YBlueCar.png'), url('@/assets/YRedCar.png');;*/
  background-attachment: fixed; /* Keep the background image fixed during scrolling */
  background-position: center center; /*3vw 10vh, 10vw 50vh, 6vw 15vh ;*/
  background-size: cover;
}

/*
html {
  background-color: rgb(4, 63, 4);
}
*/
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #87a2bd;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script setup>
  //import router from 'server/routes';
  import { ref, computed , provide, onMounted
    //, toValue
    //, watch
  } from 'vue';
  import DebugTools from '@/components/DebugTools';
  import { useRoute } from 'vue-router';
  const userAgent=ref('');
  onMounted(()=>{
    userAgent.value = window.navigator.userAgent;
  });

  const debugDialog = ref(false);

  const refVersionButton = ref(null);
  import { onLongPress } from '@vueuse/core'
  function onLongPressCallbackHook() {
    debugDialog.value=true;
  }
  onMounted(()=>{
    console.log('about to add  longPress handler refVersionButton is null? ' + (null==refVersionButton.value) )
    onLongPress(
      refVersionButton,
      onLongPressCallbackHook,
      {
        delay:4000,
      }
    )
  })

  //const bgClass=ref('kBackgroundBlue');
  //const toggleBgClass = useToggle(bgClass, 'kBackgroundBlue', 'kBackgroundYellow');
  import {useConditionalAlerts} from '@/composables/useConditionalAlerts';
  const { c_alert , set_alerts } = useConditionalAlerts();

  //const lskeys=Object.keys(localStorage);
  /*
  const [isYellowBg, toggleBackground] = useToggle();
  toggleBackground(false);
  const bgClass=computed(()=>isYellowBg.value?'kBackgroundYellow':'kBackgroundBlue');
  */
  const bgClass=ref("kBackgroundBlue");

  import { /*mdiAccount,mdiDomain, */mdiNull, mdiMapMarkerOffOutline, mdiMapMarkerQuestionOutline,mdiMapMarkerCheckOutline, mdiEmailOutline,mdiChartBar,mdiPencilBoxMultiple,mdiTranslate, mdiMapMarkerMultipleOutline } from '@mdi/js';

  import { useServiceWorker} from '@/services/useServiceWorker';
  import uuidController from './controllers/UUIDController';

  const {state, lastError, isUpdateAvailable ,updateServiceWorkerNow} = useServiceWorker();
  const route = useRoute();
  const currentPath = computed(() => route.path);

  //Debug code to display menu after 5 clicks iמ one sec
  import { useDebugActions } from './services/debug/useDebugActions';
  const debugActions = useDebugActions(); //includes addRandomReport,deleteLastReport,resetAll
    // consider refactoring to "useAfterNtimesPerPeriod" from here ----V

  // Use an array to keep track of the timestamps of the last five clicks
  let timestamps = ref([]);
  const handleDebugClick = () => {
    const currentTime = Date.now(); // Get current timestamp in milliseconds
    timestamps.value.push(currentTime); // Add the current time to the array

    if (timestamps.value.length > 5) {
      // If there are more than five timestamps, remove the oldest one
      timestamps.value.shift(); // This ensures we are always looking at the latest five clicks
    }

    if (timestamps.value.length === 5) {
      // Check if the latest five clicks happened within one second
      const timeSpan = timestamps.value[4] - timestamps.value[0];
      if (timeSpan <= 1000) {
        debugDialog.value=true;
        // If five clicks occurred within one second, do something
        // Place your action here

        // After action, you can clear the timestamps if you want to wait for another clear set of five clicks
        // Or you can leave it if you want to continue checking with every new click
        // timestamps.value = []; // Uncomment this if you want to reset after the action
      }
    }
  }


//import { usePermission } from '@vueuse/core';
import { useGpsService } from '@/composables/useGpsService';
const gpsService= useGpsService();

const gps_resume=()=>{

  try{
    c_alert('calling resume');
    c_alert(gpsService.resume);
    gpsService.resume();
    c_alert('after calling resume');
  }
  catch(e) {
    c_alert('exception calling resume' , e);
  }
}
const gps_pause=()=>{
try{
  c_alert('calling pause');
  gpsService.pause();
  c_alert('after calling resume');
}
catch(e) {
  c_alert('exception calling resume', e);
}
}

const gpsIcon=computed(()=> {
  switch (gpsService.permission) {
    case null:
    case undefined:
      return mdiNull;
    case 'prompt':
      return mdiMapMarkerQuestionOutline;
    case 'denied':
      return mdiMapMarkerOffOutline;
    case 'granted':
      if (gpsService.latitude && isFinite(gpsService.latitude)) {
        return mdiMapMarkerCheckOutline;
      } else {
        return mdiMapMarkerMultipleOutline;
      }
    default:
      alert('gpsService.permission is not null, prompt, denied or granted but:' , JSON.stringify(gpsService.permission));
      return mdiMapMarkerQuestionOutline;
  }
});

  // consider refactoring to "useAfterNtimesPerPeriod" upto here ----^
  //TODO: add support for incrementing the routerViewKey to trigger reload.
  const routerViewKey=ref(0);
  const onReloadRouterView=()=>{
    routerViewKey.value++;
  };
  provide('reloadRouterView', onReloadRouterView);

  import LogDialog from '@/components/LogDialog.vue';
  import { useCircularLog } from '@/composables/useCircularLog';
  import { useGlobalCircularLog } from '@/composables/useGlobalCircularLog';

  import RecommendInstallation from '@/components/RecommendInstallation.vue';
  import PWAPermissions from '@/components/PWAPermissions';

//  import { usePermission } from '@vueuse/core';
/*  const {state:pushPermission, isSupported:pushIsSupported} = usePermission('push',{controls : true});
  const {state:notificationsPermission, isSupported:notificationsIsSupported} = usePermission('notifications',{controls : true});
  const {state:cameraPermission, isSupported:cameraIsSupported} = usePermission('camera',{controls : true});
  const {state:geolocationPermission, isSupported:geolocationIsSupported} = usePermission('geolocation',{controls : true});
  const {state:microphonePermission, isSupported:microphoneIsSupported} = usePermission('microphone',{controls : true});
*/
  const { addToLog, clearLog, logs } = useCircularLog(45);
  const { clearLog:gClearLog, logs:gLogs } = useGlobalCircularLog(45);
  //const internalLog = { addToLog, clearLog, logs };

  provide('internalLog',{ addToLog, clearLog, logs });

  import { useSyncController  } from '@/controllers/SyncController.js'
  const {
    tryToSync,
  } = useSyncController()
  const onTryForcedSync= async () => {
    tryToSync({ addToLog, clearLog, logs },true)
  }

  //non reactive state
  const version=process.env.VUE_APP_VERSION;

  // const isInstalledL=ref(false);
  // if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
  //     isInstalledL.value = true;
  // }
  import {usePWAIsInstalled} from '@/composables/usePWAIsInstalled'
  const { isInstalled /*, setInstalled, resetInstalled*/ } = usePWAIsInstalled();

  // reactive state
  const drawer=ref(true);

  import { watch } from 'vue';

  //const route = useRoute();
  const backgroundClass = ref('defaultClass'); // Initialize with a default class

  watch([bgClass,() => route.path], ([,newPath]) => {
    // Update backgroundClass based on newPath
    backgroundClass.value = newPath === '/sh2' ? bgClass.value : 'gradientBackground';
  });

import { useWebNotification } from '@vueuse/core';
const testUseWebNotification= () => {
  const {
    isSupported,
    //notification,
    show,
    //close,
    onClick,
    onShow,
    onError,
    onClose,
  } = useWebNotification({
    title: 'Hello, VueUse world!',
    dir: 'auto',
    lang: 'en',
    renotify: true,
    tag: 'test',
  })

  if (isSupported.value) {
    alert('web notification isSupported');
    show();

    onClick(()=>{alert('web notification clicked')});
    onShow(()=>{alert('web notification showen')});
    onError(()=>{alert('web notification error')});
    onClose(()=>{alert('web notification close')});
  }
}
</script>
