/* Instructions for translators:
1. Only translate the texts with single quotes  (i.e. alwyas on the right side of :)
2. Do not add,remove or change the order of lines.
3. if a text include within it tags like <b></b> (like para4 of the consentForm ) keep the tags in the translation and
   make sure the segment inside them has the same meaning as in the original.
4. if a text include some variable within {} ( like the reported_so_far key for example)
   it means that it will be replaced with an appropriate replacement -
   keep the {} and their content un translated)
5. In case of doubt of any Q's please call Eyal Peleg : 054-4638212

*/
const en = {
    $vuetify :{
      open:'open',
      close:'close',
      noDataText:'No data found'
    },
    general: {
      buttons: {
        understood:'Understood',
        continue:'Continue',
        next:'Next',
        previous:'Previous',
        close:'Close',
        sendReport:'Send report'
      }
    },
    app: {
      title:'IntoMyZone.Com',
      menu:{
        choose_language:'Choose a language',
        continue_reporting:'Continue reporting',
        ongoing_feedback:'Intermediate feedback',
        final_feedback:'Final feedback',
        contact:'Contact us',
      }
    },
    install: {
      please_install_to_continue:'Please follow the installation guidelines above.',
      after_install:'When the istallation ends, open the installed App to continue.',
      guidelines:'Installation guidelines',
      your_os_is:'Your operating system is {operatingSystem}.',
      Android:{
        para_1:'On Android devices, Please make sure your are running the latest version of google chrome.',
        para_2:'Use google chrome to go to https://app.intomyzone.com',
        para_3:'Click Chrome\'s menu button and look for install App',
        para_4:'You will be prompted to allow the installation to happen.',
        para_5:'after you allow it you can use the regualr way on your device to add the app to the desktop.',
      },
      iOS:{
        para_1:'On iPhone or iPad devices, Open Safari.',
        para_2:'Navigate to https://app.intomyzone.com',
        para_3:'At the bottom of the screen, Press {share_icon}',
        para_4:'find and select "Add to Home Screen" {add_to_home_screen_icon}',
        para_5:'Confirm the site details and press "Add".',
        para_6:'The application will be added to your home screen.',
      },
      Windows: {
        para_1:'This application is not designed to work within Microsoft Windows.',
      },
      Other: {
        para_1:'Your\'e using "{userAgent}"',
        para_2:'We are not familiar with this environment.',
        para_3:'Please take a screenshoot of this and write to our support at {email} for assistance.',
      }
    },
    view: {
      generalInfo: {
        title:'General Information',
        head1:'IntoMyZone',
        para1:'IntoMyZone is an application that invites you to immerse yourself in your environment and practice the process of measuring your momentary experiences in different surroundings',
      },
      contact: {
        can_contact_via_email:'You can contact us via {email}'
      },
      consentForm: {
        title:'Consent form',
        para1:'Request for your participation in a study investigating the relationship between the physical environment and mental well-being in urban and natural environments – IntoMyZone',
        para2:'Hello,',
        para3:'You are invited to participate in a study investigating the relationship between physical surroundings and mental well-being in urban and natural environments. The research is conducted in Israel and managed by Dr. Keren Kaplan Mintz and Prof. Dani Ben Zvi from the Department of Learning and Teaching Sciences at the University of Haifa. The Ethics Committee of the University of Haifa approved this study.',
        para4:'We would be very grateful if you agreed to participate in the study. It is important to note that participation will only be possible after we receive your consent. This consent is not binding, and you can stop participating in the study without any consequences at any time. In addition, you can delete the application at any time. However, after that, you will not be able to delete your entries and withdraw your consent of participation, since we cannot identify individual entries as we do not collect any identifying information. To give your consent, read the consent form and mark the option "I agree to participate in the study." By choosing "I agree to participate in the study," you confirm that you agree to participate in the study. You can re-access the above information and withdraw your consent through the application&apos;s settings menu.',
        para5:'',
        termsAndConditions: {
          buttonCaption:'display Terms of Use',
          title:'Terms of Use',
          head1:'',
          para1:'Goals. The research aims to understand different environments&apos; momentary and cumulative effects on a person&apos;s mental well-being. The research findings will contribute to a deeper understanding of the rehabilitative value of environmental conditions in promoting health from an ecological point of view for the public.',
          head2:'',
          para2:'Participants. We are looking for volunteers living in Israel&apos;s urban or natural environments.',
          head3:'',
          para3:'Participation. To participate in the study, online questionnaires must be filled out using the IntoMyZone application in various locations of your choice throughout Israel. If you decide to participate in the study, you will first be asked to answer an initial questionnaire about your age, gender, occupation, place of residence, lifestyle, and degree of affinity for nature. Afterward, the app. will prompt you to report on the characteristics of your environment and your feelings 3 times a day within two weeks. Also, your geographic location will be recorded using the GPS sensor on your mobile phone. We will ask for your consent to provide the geographic location when you first use the application. In addition, at the end of each report, you will be able to take a photo of something you see around you that makes you feel positive and negative and record the sound of the environment you are located at. The initial questionnaire takes about 5 minutes, and the ongoing reports take about 2 minutes. During the two weeks, notifications will be sent to your mobile phone at pre-set times. After each notification, you will have 2 hours to complete the report. If you are unable to provide a report within this time frame, you can skip this report and await the following notification. We will ask you to complete as many reports as possible, as this will allow us to receive more reliable and comprehensive data.',
          head4:'Confidentiality.',
          para4:'The participation in the study is entirely anonymous. You will not be asked to provide personal details and identifiers at any time. The data collected will be used for research purposes only and will be reported in an aggregate form (individual data will not be reported). Your responses will be stored indefinitely and secured on password-protected computers or separated disks. The photographs and audio recordings will be analyzed for research purposes only and may be displayed on social media and used for digital art exhibitions to promote the project.',
          head5:'Data.',
          para5:'The data collected may be presented as scientific reports and published in scientific journals. In addition, the data may be available to other researchers under the open science movement. Any information presented in the reports or possible publications will be anonymous and unidentifiable.',
          head6:'Study benefits.',
          para6:'The research findings will contribute to a deeper understanding of the rehabilitative value of different environmental conditions for promoting mental and physical health, thus contributing to the public. In addition, based on the findings, it will be possible to recommend balanced and beneficial urban planning for residents in the future.',
          head7:'Additional information.',
          para7:'If you are interested in receiving further information about the study, we invite you to contact the research team via email: intomyzone24@gmail.com',
          head8:'',
          para8:'',
        },
        bullet1:'I hereby declare that I agree to participate in a study investigating the relationship between the physical surroundings and mental well-being in urban and natural environments – IntoMyZone',
        bullet2:'I know that I am participating in the study of my own will, and I can stop my participation at any time without violating my rights, any harm caused, or any sanction being taken against me.',
        bullet3:'I know I am guaranteed confidentiality regarding my identity at every study stage, including scientific publications.',
        bullet4:'I hereby declare that I consented to my own free will and understood all the above.',
        bullet5:'For any questions regarding the study, you can contact the research team via intomyzone24@gmail.com',
        iagree:'I agree to participate in the study.'
      },
      sheelon1:{
        title:'Statistical Informaion',
        intro:'For profound statistical analysis, we would like to ask you to answer several questions below before starting to use the app.  All information provided will be used for research purposes only, and in any case, no private information, such as the names of the participants, will be collected.',
        NR_title:'To what extent do you agree with the following statements?',
        PA_title:'The following statements describe relation to a place of residence. Please answer with reference to where you live now to what extent you agree with each statements ((1 = \'strongly disagree\' to 5 = \'strongly agree\')?',
        WHO_title:'In relation to the following five statements, please answer: how did you feel during the last two weeks',
        agreeScaleText:'(1 = strongly diasagree to 5 = strongly agree)',
        satisfactionTexts:[
          'strongly disagree','don\'t agree','indifferent','agree','strongly agree'
        ],
        itemSets:{
            ProjectItems:[
              {value:0, title:'Independent'},
              {value:1, title:'School project'},
              {value:2, title:'Community project'},
            ],
            YesNoItems:[
              {value:1, title:'Yes'},
              {value:0, title:'No'},
            ],
            NoYesItems:[
              {value:0, title:'No'},
              {value:1, title:'Yes'}
            ],
            residence:[
              {value:1, title:'In a city'},
              {value:2, title:'In an area with less than 20,000 residents'},
              {value:3, title:'In a rural area (for example, a kibbutz)'},
              {value:4, title:'In multiple places'},
              {value:5, title:'Other'},
            ],
            house:[
              {value:10 , title:'Private house' ,hasFloors:false},
              {value:20 , title:'Apartment in a building' , hasFloors:true},
              {value:30 , title:'Hotel' , hasFloors:true},
              {value:40 , title:'Student dormitories' , hasFloors:true },
              {value:90 , title:'None of the above' ,hasFloors:false}
            ]
        },
        Qs: {
          //phase 1
          project:
            {
              label:'You are participating:',
              //items: was @:view.sheelon1.itemSets.YesNoItems
              // changed to @:view.sheelon1.itemSets.ProjectItems
            },
          schoolCode:{
            label_1:'Enter 6 digit school code',
            label_2:'Enter 6 digit community code',
            errors: {
              schoolCodeRuleOutOfRange_1:'School code must be 6 digits',
              schoolCodeRuleOutOfRange_2:'Community code must be 6 digits'
            }
          },
          //phase 2
          age: {
            label:'How old are you?',
            errors:{
              ageRuleOutOfRange:'age must be between 6 and 99'
            }
          },
          gender:
          {
            label:'What is your gender?',
            items:[
              {value:1, title:'Female'},
              {value:0, title:'Male'},
              {value:2, title:'Non-binary or gender diverse'},
              {value:3, title:'Rather not say'},
            ]
          },
          israel:
          {
            label:'Were you born in Israel?',
            //items: @:view.sheelon1.itemSets.NoYesItems
          },

          evacuated:{
            label: "changed residence due to the war?",
            //items: @:view.sheelon1.itemSets.NoYesItems

          },
          residenceChildhood:{
            label: "What kind of place you grew up in?",
          },

          residenceName:{
            label: "Where do you currently live?",
            label_evacuated: "What is your permanent residence prior to the evacuation?",
            //list of vilages is injected automatically
            errors:{
              noDataText:'No such village was found'
            }
          },
          //phase 3
          residencePermanent:{
            label:'In what kind of residence do you live in permanently (your permanent home)?'
          },
          residenceBefore:{
            label:'In what kind of residence you used to live in before the evacuation?'
          },
          residenceAfter:{
            label:'In what kind of residence do you live in now?'
          },

          houseNow:{
            label: "What is the kind of housing you live in today?",
          },
          houseBefore:{
            label: "What was the kind of the housing you used lived in before the evacuation?",
          },
          houseAfter:{
            label: "What is the kind of housing you live in today?",
          },
          floor: { // used for all house* Q's where the  hasFloors is true for the selected answer
            label: "In which floor?"
          },

          educationLevel:{
            label: "What is your highest level of education?",
            items:[
              {value:1, title:'junior high school'},
              {value:2, title:'high school with no diploma'},
              {value:3, title:'high school with diploma'},
              {value:4, title:'apprenticeship-technical training'},
              {value:5, title:'university BA'},
              {value:6, title:'university MA or PhD'},
            ]
          },

          //phase 3
          NR_1:{label: "My ideal vacation spot would be a remote wilderness area"},
          NR_2:{label:'I always think about how my actions affect the environment',},
          NR_3:{label:'My connection to nature and the environment is a part of my spirituality',},
          NR_4:{label:'I take notice of nature wherever I am',},
          NR_5:{label:'My relationship with nature is an important part of who I am',},
          NR_6:{label:'I feel very connected to all living things and the earth',},
          //phase 4

          PA_nature1:{label:'I am very attached to the natural environment in this place'},
          PA_nature2:{label:'When I am away I miss the local nature'},
          PA_nature3:{label:'The nature in this place makes me feel a sense of belonging to this place'},
          PA_nature4:{label:'The nature in this place reflects who I am'},
          PA_people1:{label:'I feel attached to the people in this place'},
          PA_people2:{label:'When I am away I miss the people who live here'},
          PA_people3:{label:'The people here make me feel like I belong to this place'},
          PA_people4:{label:'The connections I have with people in this place make me feel like I belong here'},
          PA_identity1:{label:'This place reflects who I am'},
          PA_identity2:{label:'I am attached to this place'},
          PA_identity3:{label:'I feel a sense of belonging to this place'},
          PA_identity4:{label:'When I am away I miss this place'},
          //phase 5

          WHO_1:{label:'I have felt cheerful in good spirits.'},
          WHO_2:{label:'I have felt calm and relaxed.'},
          WHO_3:{label:'I have felt active and vigorous.'},
          WHO_4:{label:'I woke up feeling fresh and rested.'},
          WHO_5:{label:'My daily life has been filled with things that interest me.'},

          WHO_Headers_5: 'All of the time',
          WHO_Headers_4: 'Most of the time',
          WHO_Headers_3: 'More than half the time',
          WHO_Headers_2: 'Less than half the time',
          WHO_Headers_1: 'Some of the time',
          WHO_Headers_0: 'At no time'
        }
      },
      sheelon2:{
        phase0: {
          goodToSeeYouAgain:'Hello. Pleasure to see you again!.',
          readyLetsGo:'Are you ready to fill in another report? let\'s go!',
          thanksForJoining:'Thank you for joining us.',
          thrilledYourWithUs:'We are excited to have you.',
          youStartTommorow:'You can start reporting tomorrow.',
          thanksForYouRecentReport:'Thank you for submitting the previous report.',
          oopsYouMissed:'Oops, you missed the previous reporting opportunity',
          nextReportWillBeAvailable:'The next report will be ready in {hour}',
          thanksForParticipating:'The reporting period has ended, thank you very much for your participation in the project',
          showReports:'Final feedback',
          timeLeft:'Time remaining: {time} minutes',
          reminders:{
            display_reminder_notifications:'Display reporting reminder notification',
            explain_permissions:'Choosing reminder notification will request suitable permissions.',
            permission_denied:'You refused to provide permissions so no remiders will be sent. You have to provide the right permissions on your device.',
          }

        },

        agreeExtent:'To what degree do you agree with the following statments?',

        nearBy:'Right now, in the area near me:',
        atTheDistance:'In the distant view (above about 100 meters)',

        microphone_permissions_are_needed:'To enable audio recording, suitable permissions must be given on your device',
        recording_prompt_v2:'Now we will ask you to record 30 seconds of the sounds near you (The record saved concerns the volume only)',
        start_recording:'Start recording',
        stop_recording:'End recording',
        recording_has_ended:'Thank you, the recording has ended.',

        camera_permissions_are_needed:'To enable photo taking, suitable permissions must be given on your device',
        photo_pos_prompt:'Please take a photo of something you see around you that makes you feel positive. Please do not photograph people.',
        photo_neg_prompt:'Please take a photo of something you see around you that makes you feel negative. Please do not photograph people.',
        open_camera:'Open camera',
        take_picture:'Take a photo',
        dont_use_this_image:'Discard photo',

        form_filled_press_send:'You have finished filling out the form. Click the "report" button below to submit the report.',

        Qscales: {
          stai_1_4:"Right now, I would describe my mood as (from 1='not at all' to- 4='very much')",
          panas_1_5:"Right now, I feel (from 1='not at all' to- 5='very much')"
        },
        Qs: {
          //phase 1
          in_out:
            {
              label:'Right now, I am located:',
              items:[{value:0, title:'Inside'},
                     {value:1, title:'Outside'},]
            },
          company:{
            label:'Right now, I am with:',
            items:[
              {value:1, title:'No one'},
              {value:2, title:'Family'},
              {value:3, title:'A pet'},
              {value:4, title:'Friends'},
              {value:5, title:'Other'},
            ]
          },
          //phase 2
          activity:
          {
            label:'Right now, I am engaged with:',
            items:[
              {value:1, title:'Work'},
              {value:2, title:'Studies'},
              {value:3, title:'Shopping'},
              {value:4, title:'Housework'},
              {value:5, title:'Leisure'},
              {value:6, title:'Rest'},
              {value:7, title:'On my way to somewhere'},
              {value:9, title:'Sports'},
              {value:8, title:'Other'},
            ]
          },
          /// Those are for people Outside...
          location: {
            label:'Right now, I am located at:',
            items:[
              {value:1, title:'House garden'},
              {value:2, title:'Street'},
              {value:3, title:'Beach'},
              {value:4, title:'Urabn garden'},
              {value:4, title:'Community garden'},
              {value:5, title:'Shopping center'},
              {value:6, title:'Park'},
              {value:7, title:'Natural area'},
              {value:8, title:'Other'},
            ]
          },
          //nearBy
          trees:{
            label: "I see strees:",
            items:[
              {value:1, title:'Not at all'},
              {value:2, title:'Between 1-3'},
              {value:3, title:'Between 4-10'},
              {value:4, title:'Between 11-20'},
              {value:5, title:'Above 20'},
            ]
          },
          bushes:{
            label: "I see flowers:",
            items:[
              {value:1, title:'Not at all'},
              {value:2, title:'Between 1-3'},
              {value:3, title:'Between 4-10'},
              {value:4, title:'Between 11-20'},
              {value:5, title:'Above 20'},
            ]
          },
          carsp:{
            label: "I see parked cars:",
            items:[
              {value:1, title:'No parked cars'},
              {value:2, title:'Between 1 and 3'},
              {value:3, title:'Between 4-10'},
              {value:4, title:'Between 11-20'},
              {value:5, title:'Above 20'},
            ]
          },
          carsd:{
            label: "I see driving cars:",
            items:[
              {value:1, title:'No driving cars'},
              {value:2, title:'Between 1-3'},
              {value:3, title:'Between 4-10'},
              {value:4, title:'Between 11-20'},
              {value:5, title:'Above 20'},
            ]
          },
          grass:{
            label: "I see lawn:",
            items:[
              {value:1, title:'Yes'},
              {value:2, title:'No'},
              {value:3, title:'Not sure'},
            ]
          },
          birds:{
            label: "I see or hear birds :",
            items:[
              {value:1, title:'Yes'},
              {value:2, title:'No'},
              {value:3, title:'Not sure'},
            ]
          },
          // phase
          // In the distant view (above about 100 meters)
          blue:{
            label: "I see a sea or a lake:",
            items:[
              {value:1, title:'Yes'},
              {value:2, title:'No'},
              {value:3, title:'Not sure'},
            ]
          },
          green:{
            label: "I see green view :",
            items:[
              {value:1, title:'Yes'},
              {value:2, title:'No'},
              {value:3, title:'Not sure'},
            ]
          },
          //phase 3
          stai_1:{label: "Tense"},
          stai_2:{label: "Content"},
          stai_3:{label: "Relaxed"},
          panas_1: {label: "Inspired"},
          panas_2: {label: "Hostile"},
          panas_3: {label: "Strong"},
          panas_4: {label: "Distressed"},
          panas_5: {label: "Determined"},
          panas_6: {label: "Irritable"},

          //phase 6 - audio
          sound:{
            label:'What is the primary source of the sound you have recorded?',
            items:[
              {value:1, title:'People talking'},
              {value:2, title:'Dogs barking'},
              {value:3, title:'Traffic'},
              {value:4, title:'Music'},
              {value:6, title:'Birds chirping'},
              {value:5, title:'Other'},
            ]
          },

        },

        locationPermissions: {
          title: 'Location tracking permissions',
          para1:'To explore the relationship between physical location and emotional state, we would like to access your location while using the application and during these times only. ',
          para2:'After clicking "Next", we will ask for your location tracking permission. Please confirm that you agree to this. Participation in the activity is not possible without this permission.',
          //para2: 'If location services are currently disabled on your device, please exit the app, enable location services, and then restart the app.',
          //denied:'You did not grant the permission and therefore participation in the activity is not possible. The suitable location tracking permission must be enabled on your device.',
          denied_or_inactive: "If location services are currently disabled on your device, please enable them and return to the app. If you denied permission, please grant the permissions through your device settings to participate in the activity."
        },

      },
      get_feedback: {
        reported_so_far:'So far you have reported {n} times.',
        where_were_you:'Where were you during the reports:',
        outside:'Outside',
        inside:'Inside',
        sea_or_lake_view:'Sea or lake view',
        green_view:'Green view',

        who_were_you_with:'Who were you with during the reports:',
        alone:'Alone',
        with_pet:'With a pet',
        with_friends:'With friends',
        with_family:'With family',
        other:'Other',

        trees_seen:'Number of trees seen:',
        carsd_seen:'Number of moving cars seen:',
        range_none:'None',
        range_1_3:'Between 1-3',
        range_4_10:'Between 4-10',
        range_11_20:'Between 11-20',
        range_over_20:'Above 20',
        number_of_times_you_reported_of:'Number of times you reported on:',
        reported_feeling_avg:'The average of feelings you reported (in the range between 1-4) is:',
        no_reports_yet:'Ongoing feedback on your reports will appear here. You must report at least once to receive the ongoing feedback.',

        data_will_be_here:'Here will apear starting from {end_date} (at the end of two weeks participation) a summarized personal feedback of how different sites and experiences relate to your emotional state.',
        data_N_required:'For this purpose, you will need to complete at least {n_req} reports.',
        data_requirements:'You are missing {n} reports to receive a summarized personal feedback at the end of two weeks participation.',
        your_reporting_progress:'Your progress in reports',

        did_N_reports:'Congratulations! You have completed {n_req} reports. A summarized personal feedback will appear at {end_date} (at the end of two weeks participation)',
        did_N_continue_reporting: 'In any case, for the feedback to be as accurate as possible, it is recommended to continue submitting reports.',

        ended_without_enough_reports:'Thank you very much for your participation. Since enough reports were not received {n_req}, the system is unable to generate a summarized personal feedback. You can still view your intermediate feedback and reflect on the main activities you engaged in and sights you saw.',

        we_created:'From the submitted data, we created indices for three types of emotions:',
        indicators:'Positive emotions, negative emotions, and stress.',
        averages:'Below are the averages of emotions you experience in different situations.',
        averages_feelings:'The averages of positive and negative emotions appear in the range between 1-5.',
        averages_stress:'The averages of stress appear in the range between 1-4.',
        limits_note:'Please note: the graphs include a selection of variables but not all of the study variables.',

        final:{
          positive_and_negative_feelings:'positive and negative emotions:',
          stress:'stress:',
          categories: {
            g_in_out_1:'outside',
            g_in_out_0:'inside',
            blue_1:'lake or sea view',
            g_green_1:'green view',
            g_birds_1:'birds',
            g_company_1:'alone',
            g_company_2:'with friends',
            g_company_3:'with family',
            g_company_4:'with pet',
            g_trees_1:'no trees',
            g_trees_2:'1-3 trees',
            g_trees_3:'4-10 trees',
            g_trees_4:'11-20 trees',
            g_carsd_1:'No driving cars',
            g_carsd_2:'1-3 driving cars',
            g_carsd_3:'4-10 driving cars',
            g_carsd_4:'11-20 driving cars',
          }
        },
        no_information_word_1:'No reports',
        no_information_word_2:'In this category',

      },
    }
  };

export {en};